import { Avatar, Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { CSSProperties } from "styled-components";
import styled from "styled-components";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles/Swiper.css";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import axios from "axios";
import JCB from "./images/JCB.svg";
import MasterCard from "./images/mastercard.svg";
import Visa from "./images/visa.svg";
import Rabbit from "./images/Rabbit.svg";
import KBank from "./images/KBank.svg";
import SCB from "./images/SCB.svg";
import PaymentSuccess from "./images/PaymentSuccess.svg";

import {
  checkoutArrow,
  basketDetailsNote,
  basketTrashBin,
  checkoutClock,
  checkoutShop,
  checkoutDistance,
  checkoutCheck,
  checkoutCopy,
  StyledPaymentModal,
  IconSaveQR,
  IconShare,
} from "@components";
import { IInfo } from "@interfaces";
import { enumNavigation } from "@configs";
import { selectMerchant, setDetailUser, store } from "@redux";
import { numberFormatter } from "@utils";
import { useEffect, useLayoutEffect, useState } from "react";
import { array } from "prop-types";
import { reloadResources } from "i18next";
const baseUrl = process.env.REACT_APP_API_STORE_ADMIN;

interface IProps {
  themeColor: string;
  userInfo: IInfo | null;

  under: enumNavigation;
  handleToggleUnder: (value: enumNavigation) => void;
  handleToggleQrCode: () => void;
  handleSwitchUserSetting: () => void;
  handleSwitchTier: () => void;
  // handleCoupon: () => void;
}

interface IPrebooingLocal {
  booking_return: IBookingReturn;
  schedule_return: null;
}

interface IBookingReturn {
  before_vat_total: number;
  booking_code: string;
  cash_change: number;
  cash_receive: number;
  discount_in_baht_by_point: number;
  discount_regular: number;
  earn_points: number;
  grand_total: number;
  net: number;
  note: string;
  raw_grand_total: number;
  rounding: string;
  service_charge: number;
  service_charge_percent: number;
  service_date: string;
  service_time: string;
  store_name: string;
  sum_discount: number;
  summary_net_price: number;
  used_points: number;
  vat_percent: number;
  vat_total: number;
  vat_type: string;
  booking_service: IService[];
  organization_id: string;
  _id: string;
}

interface IService {
  before_vat: number;
  booking_staff: IBookingStaff[];
  consumption: number;
  discount: number;
  discount_in_baht: number;
  discount_in_percent: number;
  duration: {
    value: number;
    unit: string;
  };
  net: number;
  net_price: number;
  service_charge: number;
  service_code: string;
  service_id: string;
  service_name: string;
  standard_price: number;
  sub_total: number;
  variant: string;
  vat: number;
}

interface IBookingStaff {
  staff_code: any;
  staff_name: string;
}

interface IPayOnlineRes {
  _id: string;
  before_vat_total: number;
  booking_code: string;
  booking_service: IService[];
  cash_change: number;
  cash_receive: number;
  discount_in_baht_by_point: number;
  discount_regular: number;
  earn_points: number;
  grand_total: number;
  net: number;
  note: string;
  raw_grand_total: number;
  rounding: string;
  service_charge: number;
  service_charge_percent: number;
  service_date: string;
  service_time: string;
  store_name: string;
  sum_discount: number;
  summary_net_price: number;
  used_points: number;
  vat_percent: number;
  vat_total: number;
  vat_type: string;
  organization_id: string;
  payment_detail: {
    is_paid: boolean;
    is_paysolution: true;
    payment_type: string;
    resp_paysolution: {
      expiredate: string;
      image: string;
      orderNo: string;
      orderdatetime: string;
      referenceNo: string;
      total: number;
    };
    sub_payment_type: string;
  };
}

export const ComponentCheckout = (props: IProps) => {
  const history = useHistory();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [count, setCount] = useState<number>(600000);
  const [isStarted, setIsStarted] = useState<boolean>(false);
  //page hook
  const { t } = useTranslation();
  const organization_code = useSelector(selectMerchant).subCode;
  const [tab, setTab] = useState<string>("summary");
  const [product, setProduct] = useState<string>("service");
  const [payment, setPayment] = useState<IPayOnlineRes>();
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [cardDetail, setCardDetail] = useState();
  const [payType, setPayType] = useState("cash");
  const [storeDetail, setStoreDetail] = useState({
    address: "",
    store_name: "",
    organization_name: "",
  });
  const dispatch = useDispatch();
  //page variable
  const {
    themeColor,
    userInfo,

    under,
    handleToggleUnder,
    handleToggleQrCode,
    handleSwitchUserSetting,
    handleSwitchTier,
    // handleCoupon,
  } = props;

  const handleDetail = () => {
    dispatch(setDetailUser());
  };
  // Basket Codes

  const preBookingBody = JSON.parse(localStorage.getItem("preBookingBody") || "{}");
  let basketItem: any = [];

  const bookingData: IPrebooingLocal = JSON.parse(localStorage.getItem("bookingData") || "{}");
  useEffect(() => {
    if (history.location.search === "?from=buycard") {
      setProduct("card");
      let localCard = JSON.parse(localStorage.getItem("buyCardLocalStorage") || "{}");
      if (Object.keys(localCard).length > 0) {
        setCardDetail(localCard);
      }
    }
  }, [history]);

  useEffect(() => {
    if (!isStarted) return;
    const interval = setInterval(() => {
      setCount(count - 1000);
      startTime();
    }, 1010);
    if (count <= 0) {
      clearInterval(interval);
      setIsRunning(false);
    }
    return () => clearInterval(interval);
  }, [count, isStarted]);

  useEffect(() => {
    if (!isRunning) return;
    const interval = setInterval(() => {
      fetchStatus();
    }, 3000);
    return () => clearInterval(interval);
  }, [isRunning]);
  const startTime = () => {
    setIsRunning(true);
  };
  const stopTime = () => {
    setIsRunning(false);
  };

  if (preBookingBody.booking_service) {
    const getLocalStorage: any = preBookingBody.booking_service;
    // basketItem.push(getLocalStorage);

    for (let i = 0; i < getLocalStorage.length; i++) {
      basketItem.push(getLocalStorage);
    }
  }

  // Basket Codes
  const [showMore, setShowMore] = useState(true);
  //@ts-ignore
  const token = store.getState().auth.auth?.accessToken;
  const profile_id = store.getState().auth.userInfo?._id;
  const organization_id = store.getState().auth.userInfo?.merchant?._id;
  const username = store.getState().auth?.userInfo?.username;
  // Create Booking
  const headers = {
    "Content-Type": "application/json",
    "access-token": token,
  };

  useEffect(() => {
    let ueStoreCode = localStorage.getItem("store_code");
    if (ueStoreCode) {
      axios
        .get(
          `${baseUrl}store/getStore?organization_code=${organization_code}&store_code=${ueStoreCode}`,
          { headers: headers }
        )
        .then((data) => {
          if (data.data.error === "0") {
            let outStore = {
              address: data?.data?.result?.address,
              store_name: data?.data?.result?.store_name,
              organization_name: data?.data?.result?.organization_data?.name,
            };
            setStoreDetail(outStore);
          } else {
            alert("Something is wrong with get store");
          }
        });
    }
  }, []);

  const fetchStatus = () => {
    if (payment?.organization_id && payment?._id) {
      axios
        .get(
          `${baseUrl}booking/getBookingPaymentStatus?organization_id=${payment?.organization_id}&booking_id=${payment?._id}`,
          { headers: headers }
        )
        .then((data) => {
          if (data?.data?.result?.is_paid) {
            stopTime();
            setOpenModal(true);
          }
        })
        .catch((err) => console.log(err, "\nSomething wrong, try again!"));
    }
  };

  const changePaidStatus = () => {
    if (payment?.organization_id && payment?._id) {
      let payload = {
        organization_id: bookingData?.booking_return?.organization_id,
        booking_id: bookingData?.booking_return?._id,
        is_paid: true,
      };
      axios
        .post(`${baseUrl}booking/payOfflineForBooking`, payload, { headers: headers })
        .then((data) => {
          console.log("change Paid status", data?.data);
        });
    }
  };

  const payCheckout = () => {
    if (payType === "paySo") {
      let payload = {
        organization_id: bookingData?.booking_return?.organization_id,
        booking_id: bookingData?.booking_return?._id,
      };
      axios
        .post(`${baseUrl}booking/payOnlineForBooking`, payload, { headers: headers })
        .then((data) => {
          setTab("QRSection");
          setIsStarted(true);
          setPayment(data?.data?.result);
        });
    } else if (payType === "cash") {
      setOpenModal(true);
    }
  };
  const createBooking = () => {
    axios
      .post(`${baseUrl}booking/createBooking`, preBookingBody, { headers: headers })
      .then((data) => {
        if (data.data.msg !== 1) {
          localStorage.removeItem("preBookingBody");
          // localStorage.setItem(
          //     "bookingDetails",
          //     JSON.stringify(data.data.result.booking_return)
          // );
          // history.push("/mybooking");
          setOpenModal(true);
        } else {
          alert(data.data.msg);
        }
      })
      .catch((err) => console.log(err, "\nSomething wrong on Pre-Booking, try again!"));
  };
  const [booking, setBooking] = useState(false);

  // Basket Codes
  const buyCardLocalStorage = JSON.parse(localStorage.getItem("buyCardLocalStorage") || "{}");
  const preCardBody = {
    organization_id: organization_id,
    organization_code: organization_code,
    profile_id: profile_id,
    card_code: buyCardLocalStorage.card_code,
    amt: 1,
  };

  const buyCardFunc = () => {
    axios
      .post(`${baseUrl}card/clientBuyCard`, preCardBody, {
        headers: headers,
      })
      .then((data) => {
        if (data.data.msg !== 1) {
          localStorage.removeItem("buyCardLocalStorage");
          history.push("/cards");
        } else {
          alert(data.data.msg);
        }
      })
      .catch((err) => console.log(err, "\nSomething wrong on Pre-Booking, try again!"));
  };

  return (
    <>
      {tab === "summary" && (
        <div className="wholePage">
          <div className="topStoreInfo">
            <h1 className="text-center">
              {storeDetail?.organization_name} ({storeDetail?.store_name})
            </h1>
            <p className="text-center">
              <span className="baht">฿</span>
              {product === "service" && preBookingBody.total_price}
              {product === "card" && buyCardLocalStorage.standard_price}
            </p>
          </div>
          <div className="basketSummary">
            <h1 className="SummaryTitle">Basket summary</h1>
            <hr />
            {product === "service" &&
              Object.keys(bookingData).length > 0 &&
              bookingData?.booking_return?.booking_service?.length > 0 &&
              bookingData?.booking_return?.booking_service?.map((item, i) => {
                return (
                  <div className="basketItems" key={i}>
                    <div className="leftSide">
                      <div className="serviceImage">
                        <img src="https://i.ibb.co/T4Vvv6g/Image-90.png" />
                      </div>
                      <div className="serviceDetails">
                        <h1>{item?.service_name}</h1>
                        <ul>
                          <li>
                            {item?.duration?.value}
                            {item?.duration?.unit}
                          </li>
                          <li>{item?.variant}</li>
                          {item?.booking_staff?.length > 0 ? (
                            <li>{item?.booking_staff[0]?.staff_name}</li>
                          ) : (
                            <li>no staff</li>
                          )}
                          {/* <li>{item?.booking_staff[0] || "no staff"}</li> */}
                        </ul>
                      </div>
                    </div>
                    <div className="rightSide">
                      {/* <div className="noteButton">
                        <button>Notes</button>
                      </div> */}
                      <div className="priceQuantity">
                        <h3>฿ {item?.standard_price}</h3>
                        <h4>x {item?.consumption}</h4>
                      </div>
                    </div>
                  </div>
                );
              })}
            {/* {product === "service" &&
              bookingData?.booking_return?.booking_service?.length > 0 &&
              bookingData?.booking_return?.booking_service.map((item, i) => {
                return (
                  <div className="basketItems" key={i}>
                    <div className="leftSide">
                      <div className="serviceImage">
                        <img src="https://i.ibb.co/T4Vvv6g/Image-90.png" />
                      </div>
                      <div className="serviceDetails">
                        <h1>{item?.service_name}</h1>
                        <ul>
                          <li>
                            {item?.duration?.value}
                            {item?.duration?.unit}
                          </li>
                          <li>{item?.variant}</li>
                          <li>{item?.booking_staff[0] || "no staff"}</li>
                        </ul>
                       
                      </div>
                    </div>
                    <div className="rightSide">
                     
                      <div className="priceQuantity">
                        <h3>฿ {item?.standard_price}</h3>
                        <h4>x {item?.consumption}</h4>
                      </div>
                    </div>
                  </div>
                );
              })} */}
            {product === "card" && buyCardLocalStorage && (
              <div className="basketItems">
                <div className="leftSide">
                  <div className="serviceImage">
                    <img src="https://i.ibb.co/T4Vvv6g/Image-90.png" />
                  </div>
                  <div className="serviceDetails">
                    <h1>{buyCardLocalStorage.card_name}</h1>
                    <ul>
                      <li>{buyCardLocalStorage.description}</li>
                    </ul>
                  </div>
                </div>
              </div>
            )}

            {preBookingBody.booking_service && (
              <>
                {preBookingBody.booking_service.length > 1 && (
                  <div
                    className="showMoreDiv text-center"
                    onClick={() => {
                      setShowMore(!showMore);
                    }}
                  >
                    <button>
                      {showMore ? "Show more" : "Show less"}{" "}
                      <span className={showMore ? "" : "reverse"}>
                        <StyledArrow />
                      </span>
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
          {product === "service" && (
            <div className="basketSummary boxMargin">
              <h1 className="SummaryTitle">Booking details</h1>
              <hr />
              <p>
                <StyledClock /> วันที่ 7 พฤศิกายน 2021 เวลา 15:00
              </p>
              <p>
                <StyledShop /> Lavish Salon สาขา เอกมัย
              </p>
              <p>
                <StyledDistance /> 101 ซอยพระพินิจ ถนนนราธิวาสราชนครินทร์
                <br />
                แขวงทุ่งมหาเมฆ เขตสาทร กรุงเทพ 10120
              </p>
            </div>
          )}

          <div className="basketSummary boxMargin">
            <h1 className="SummaryTitle">Payment method</h1>
            <hr />
            <div className="radioSection">
              {/* <div className="radioItem">
                <label htmlFor="cash_on_arrival">Cash on arrival / delivery</label>{" "}
                <input
                  className="radio-custom"
                  type="radio"
                  name="cash_on_arrival"
                  id="cash_on_arrival"
                />
                <label htmlFor="cash_on_arrival" className="checked">
                  <StyledCheck />
                </label>
              </div> */}
              <div className="radioItem">
                <label htmlFor="cash">Cash on arrival /delivery</label>{" "}
                <input
                  className="radio-custom"
                  type="radio"
                  name="Payment"
                  id="cash"
                  onClick={() => {
                    setPayType("cash");
                  }}
                />
                <label htmlFor="cash" className="checked">
                  <StyledCheck />
                </label>
              </div>
              <div className="radioItem">
                <label htmlFor="bank_mobile_app">Bank mobile application</label>{" "}
                <input
                  className="radio-custom"
                  type="radio"
                  name="Payment"
                  id="bank_mobile_app"
                  onClick={() => {
                    setPayType("paySo");
                  }}
                />
                <label htmlFor="bank_mobile_app" className="checked">
                  <StyledCheck />
                </label>
              </div>
              <div className="radioItem">
                <label htmlFor="credit_card">
                  Credit card <br />
                  <img className="cardImages" src={JCB} />
                  <img className="cardImages" src={MasterCard} />
                  <img className="cardImages" src={Visa} />
                </label>{" "}
                <input
                  className="radio-custom"
                  type="radio"
                  name="Payment"
                  id="credit_card"
                  onClick={() => {
                    setPayType("credit");
                  }}
                />
                <label htmlFor="credit_card" className="checked">
                  <StyledCheck />
                </label>
              </div>
              <div className="radioItem">
                <label htmlFor="rabbit_pay">
                  Rabbit LINE Pay <br />
                  <img className="cardImages rabbit" src={Rabbit} />
                </label>
                <input
                  className="radio-custom"
                  type="radio"
                  name="Payment"
                  id="rabbit_pay"
                  onClick={() => {
                    setPayType("line");
                  }}
                />
                <label htmlFor="rabbit_pay" className="checked">
                  <StyledCheck />
                </label>
              </div>
            </div>
          </div>
          <div className="basketSummary boxMargin">
            <h1 className="SummaryTitle">Bank transfer</h1>
            <hr />
            <div className="radioSection">
              <div className="radioItem">
                <label className="BankLabel" htmlFor="KBank">
                  <img src={KBank} />
                  <ul className="threeLine">
                    <li>KASIKORNBANK PUBLIC COMPANY LTD.,</li>
                    <li>0733636424</li>
                    <li>Lavish Co., Ltd.</li>
                  </ul>
                </label>
                <input className="radio-custom" type="radio" name="Bank" id="KBank" />
                <label htmlFor="KBank" className="checked">
                  <div className="copyIcon">
                    <StyledCopy />
                  </div>
                  <StyledCheck />
                </label>
              </div>
              <div className="radioItem">
                <label className="BankLabel" htmlFor="SCBBank">
                  <img src={SCB} />
                  <ul className="threeLine">
                    <li>SIAM COMMERCIAL BANK LTD.,</li>
                    <li>3182500144</li>
                    <li>David Lin</li>
                  </ul>
                </label>
                <input className="radio-custom" type="radio" name="Bank" id="SCBBank" />
                <label htmlFor="SCBBank" className="checked">
                  <div className="copyIcon">
                    <StyledCopy />
                  </div>
                  <StyledCheck />
                </label>
              </div>
              <div className="noteButton">
                <button className="attachSlip">+ Attach Slip</button>
              </div>
            </div>
          </div>
          <div className="buttonSection">
            <button
              className="Next"
              onClick={() => {
                if (product === "card") {
                  buyCardFunc();
                }
                if (product === "service") {
                  payCheckout();
                }
              }}
            >
              <span>Make Payment</span>
            </button>
          </div>
        </div>
      )}
      {tab === "QRSection" && (
        <div className="QRPage">
          <div className="top-section">
            <h2>
              Time remaining{" "}
              <span>
                {Math.floor(count / 1000 / 60)}:{(count % 60000) / 1000}
              </span>
            </h2>
            <p className="desc">
              *QR code is valid for 10 minutes. Please refresh and try again after expired.
            </p>
            <div className="qr-section">
              <div className="header-section">
                <div className="box-section">
                  <div className="header-box">
                    <img className="qr-icon" src="/images/qr-icon.png" />
                  </div>
                  <div className="header-box">
                    <p>THAI QR</p>
                    <p>PAYMENT</p>
                  </div>
                </div>
              </div>
              <div className="body-section">
                <img className="promptpay-icon" src="/images/promptpay-icon.png" />
                <img className="qr-img" src={payment?.payment_detail?.resp_paysolution?.image} />
                <p className="acc-text">Name Account : Rocket digital</p>
                <p className="price">
                  Total {payment?.payment_detail?.resp_paysolution?.total} Baht
                </p>
                <div className="action">
                  <p>
                    <IconSaveQR /> บันทึก
                  </p>
                  <p>
                    <IconShare /> แชร์
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-section">
            <h2>วิธีการชำระเงิน ?</h2>
            <p>
              1. กด <span>‘บันทึก’</span> รูป QR Code พร้อมเพย์ด้านบนลงในโทรศัพท์มือถือของคุณ
            </p>
            <p>2. เปิดแอปธนาคาร และล็อกอิน</p>
            <p>
              3. ไปที่เมนู <span>สแกน QR Code</span> และเลือกเปิดรูป QR Code
              จากอัลบัมรูปในโทรศัพท์ของคุณ
            </p>
            <p>4. ตรวจสอบรายละเอียด และจ่ายบิลทันที</p>
          </div>
          {/* <Button onClick={stopTime}>paid</Button> */}
          <Button onClick={changePaidStatus}>call api change status</Button>
        </div>
      )}
      <div className="checkout-modal">
        <Modal
          visible={openModal}
          // title="Confirmation slip"
          footer={null}
          width={380}
          onCancel={() => {
            history.push("/mybooking");
          }}
        // closable={false}
        >
          <div className="payment-modal-header-section">Confirmation slip</div>
          <div className="payment-modal-body">
            <div className="img">
              <img className="cardImages rabbit" src={PaymentSuccess} />
            </div>
            <div className="text-section">Booking successful !</div>
            <div className="buttonSection">
              <button
                className="Next"
                onClick={() => {
                  history.push("/mybooking");
                }}
              >
                <span>My bookings</span>
              </button>
            </div>
          </div>
        </Modal>
      </div>

      <EmptyFooterSpace />
    </>
  );
};

const EmptyFooterSpace = styled.div`
  height: ${(props) => `${props.theme.footer.height}`};
`;

const StyledArrow = styled(checkoutArrow)`
  cursor: pointer;
`;
const StyledNote = styled(basketDetailsNote)`
  cursor: pointer;
`;
const StyledBin = styled(basketTrashBin)`
  cursor: pointer;
`;
const StyledClock = styled(checkoutClock)`
  cursor: pointer;
`;
const StyledShop = styled(checkoutShop)`
  cursor: pointer;
`;
const StyledDistance = styled(checkoutDistance)`
  cursor: pointer;
`;
const StyledCheck = styled(checkoutCheck)`
  cursor: pointer;
`;
const StyledCopy = styled(checkoutCopy)`
  cursor: pointer;
`;
