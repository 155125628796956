import { Avatar, Button, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { CSSProperties } from "styled-components";
import styled from "styled-components";
import { useHistory, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./styles/Swiper.css";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import axios from "axios";
import moment from "moment";

import {
  WalkinTurnE,
  basketDetailsNote,
  basketTrashBin,
  checkoutClock,
  checkoutShop,
  checkoutDistance,
  checkoutCheck,
  checkoutCopy,
  checkoutPayment,
  cancelCloseIcon,
  cancelGreenDoneIcon,
} from "@components";
import { ICounterService, IInfo, ISetCardDetail, ISetCardRedux } from "@interfaces";
import { enumNavigation } from "@configs";
import {
  resetCardUse,
  selectMerchant,
  setCard,
  setCardDetail,
  setChannel,
  setCouterService,
  setCurrentPath,
  setDetailUser,
  store,
} from "@redux";
import { numberFormatter } from "@utils";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { array } from "prop-types";
import { reloadResources } from "i18next";
import QRCode from "react-qr-code";
import FirstCardImage from "./images/FirstCard.jpg";

interface IProps {
  themeColor: string;
  userInfo: IInfo | null;

  under: enumNavigation;
  handleToggleUnder: (value: enumNavigation) => void;
  handleToggleQrCode: () => void;
  handleSwitchUserSetting: () => void;
  handleSwitchTier: () => void;
  // handleCoupon: () => void;
}

interface ICard {
  _id: string;
  updated_date: string;
  created_date: string;
}

export const ComponentCards = (props: IProps) => {
  const history = useHistory();
  const location = useLocation();

  //page hook
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //page variable
  const {
    themeColor,
    userInfo,
    under,
    handleToggleUnder,
    handleToggleQrCode,
    handleSwitchUserSetting,
    handleSwitchTier,
    // handleCoupon,
  } = props;

  const handleDetail = () => {
    dispatch(setDetailUser());
  };
  let [all, setAll] = useState(true);
  let [storedValueTab, setStoredValueTab] = useState(false);
  let [counterTab, setCounterTab] = useState(false);
  let [packageTab, setPackageTab] = useState(false);

  const profile_id = store.getState().auth.userInfo?._id;
  const token = store.getState().auth.auth?.accessToken;
  const username = store.getState().auth?.userInfo?.username;
  const organization_code = useSelector(selectMerchant).subCode;
  const baseUrl = process.env.REACT_APP_API_STORE_ADMIN;
  const organization_id = store.getState().auth.userInfo?.merchant?._id;
  const cardUse = store.getState().cardUse;

  // Get all Cards
  let [allCards, setAllCards] = useState([]);
  // let allCards: any[] = [];
  useEffect(() => {
    axios
      .get(
        `https://api-store.rocket-tech.app/1.0.0/buycardList?organization_code=T94&is_delete=false`
      )
      .then((data) => {
        data?.data?.result.sort((a: ICard, b: ICard) => {
          let aDate = new Date(a.updated_date || a.created_date).getTime();
          let bDate = new Date(b.updated_date || a.updated_date).getTime();
          return bDate - aDate;
        });
        setAllCards(data?.data?.result.reverse());
        // for (let i = 0; i < data.data.result.length; i++) {
        //     setAllCards(data.data.result);
        // }
      })
      .catch((err) => console.log(err, "\nSomething wrong on getting all cards, try again!"));
  }, []);

  // Stored Value Cards
  let [storedValue, setStoredValue] = useState([]);
  const storedValueFunc = () => {
    axios
      .get(
        `https://api-store.rocket-tech.app/1.0.0/toggleCardTypes?organization_code=T94&is_delete=false&card_type=store value`
      )
      .then((data) => {
        data?.data?.result.sort((a: ICard, b: ICard) => {
          let aDate = new Date(a.updated_date || a.created_date).getTime();
          let bDate = new Date(b.updated_date || a.updated_date).getTime();
          return bDate - aDate;
        });
        setStoredValue(data.data.result);
      })
      .catch((err) =>
        console.log(err, "\nSomething wrong on getting stored value card type, try again!")
      );
  };

  // Counter Cards
  let [counterType, setCounterType] = useState([]);
  const counterTypeFunc = () => {
    axios
      .get(
        `https://api-store.rocket-tech.app/1.0.0/toggleCardTypes?organization_code=T94&is_delete=false&card_type=counter`
      )
      .then((data) => {
        data?.data?.result.sort((a: ICard, b: ICard) => {
          let aDate = new Date(a.updated_date || a.created_date).getTime();
          let bDate = new Date(b.updated_date || a.updated_date).getTime();
          return bDate - aDate;
        });
        setCounterType(data.data.result);
      })
      .catch((err) =>
        console.log(err, "\nSomething wrong on getting counter card type, try again!")
      );
  };

  // Package Cards
  let [packageType, setPackageType] = useState([]);
  const packageTypeFunc = () => {
    axios
      .get(
        `https://api-store.rocket-tech.app/1.0.0/toggleCardTypes?organization_code=T94&is_delete=false&card_type=package`
      )
      .then((data) => {
        data?.data?.result.sort((a: ICard, b: ICard) => {
          let aDate = new Date(a.updated_date || a.created_date).getTime();
          let bDate = new Date(b.updated_date || a.updated_date).getTime();
          return bDate - aDate;
        });
        setPackageType(data.data.result);
      })
      .catch((err) =>
        console.log(err, "\nSomething wrong on getting package card type, try again!")
      );
  };

  // My Cards
  let [showMyCards, setShowMyCards] = useState(false);

  let [myAll, setMyAll] = useState(false);
  let [myStoredValueTab, setMyStoredValueTab] = useState(false);
  let [myCounterTab, setMyCounterTab] = useState(false);
  let [myPackageTab, setMyPackageTab] = useState(false);
  const [allStore, setAllStore] = useState<any[]>([]);
  const [allService, setAllService] = useState<any[]>([]);

  // Get My All Cards
  let [myAllCards, setMyAllCards] = useState([]);

  const headers = {
    "Content-Type": "application/json",
    "access-token": token,
  };

  useEffect(() => {
    axios
      .get(
        `${baseUrl}service/getServices?organization_code=${organization_code}&xpage=1&xlimit=99&category=`,
        { headers: headers }
      )
      .then((data) => {
        if (data?.data?.error === "0") {
          let arr: any[] = [];
          data?.data?.result?.map((item: any) => {
            arr.push({
              service_code: item.service_code,
              service_id: item._id,
              service_detail: item,
              _id: item._id,
            });
          });
          setAllService(arr);
        } else {
          alert("something wrong with get services");
        }
        //
      });

    axios
      .get(`${baseUrl}store/getStores?organization_code=${organization_code}&xpage=1&xlimit=99`, {
        headers: headers,
      })
      .then((data) => {
        if (data.data.error === "0") {
          let arr: any[] = [];
          data?.data?.result.forEach((item: any) => {
            arr.push({
              address: item?.address,
              store_code: item?.store_code,
              store_id: item?._id,
              store_name: item?.store_name,
              _id: item?._id,
            });
          });
          setAllStore(arr);
        } else {
          alert("Something is wrong with get store");
        }
      });
  }, []);
  useEffect(() => {
    axios
      .get(
        `https://api-store.rocket-tech.app/1.0.0/getCardWallet?organization_code=T94&is_delete=false&profile_id=${profile_id}`
      )
      .then((data) => {
        for (let i = 0; i < data.data.result.length; i++) {
          setMyAllCards(data.data.result);
        }
      })
      .catch((err) => console.log(err, "\nSomething wrong on getting all cards, try again!"));
    // const beforeUnloadListener = () => {
    //     //Send something to back end
    //     localStorage.removeItem("bookByServiceCodeCounter");
    //     localStorage.removeItem("cardUsesCode");
    // };
    // window.addEventListener("pagehide", beforeUnloadListener);
  }, []);

  // My Store Value Card Types
  let [myStoreValue, setMyStoreValue] = useState([]);
  useEffect(() => {
    axios
      .get(
        `https://api-store.rocket-tech.app/1.0.0/getCardWallet?organization_code=T94&profile_id=${profile_id}&is_delete=false&card_type=store value`
      )
      .then((data) => {
        for (let i = 0; i < data.data.result.length; i++) {
          setMyStoreValue(data.data.result);
        }
      })
      .catch((err) => console.log(err, "\nSomething wrong on getting all cards, try again!"));
  }, []);

  // My Counter Card Types
  let [MyCounterType, setMyCounterType] = useState([]);
  useEffect(() => {
    axios
      .get(
        `https://api-store.rocket-tech.app/1.0.0/getCardWallet?organization_code=T94&profile_id=${profile_id}&is_delete=false&card_type=counter`
      )
      .then((data) => {
        for (let i = 0; i < data.data.result.length; i++) {
          setMyCounterType(data.data.result);
        }
      })
      .catch((err) => console.log(err, "\nSomething wrong on getting all cards, try again!"));
  }, []);

  // My Package Card Types
  let [MyPackage, setMyPackage] = useState([]);
  useEffect(() => {
    axios
      .get(
        `https://api-store.rocket-tech.app/1.0.0/getCardWallet?organization_code=T94&profile_id=${profile_id}&is_delete=false&card_type=package`
      )
      .then((data) => {
        for (let i = 0; i < data.data.result.length; i++) {
          setMyPackage(data.data.result);
        }
      })
      .catch((err) => console.log(err, "\nSomething wrong on getting all cards, try again!"));
  }, []);

  useEffect(() => {
    dispatch(resetCardUse());
    localStorage.removeItem("path");
  }, []);

  // Show Basket
  let [showCardsBasket, setShowCardsBasket] = useState(false);

  // Each Card Details before Use
  let [beforeCardUse, setbeforeCardUse] = useState({
    applicable_services_store_value: [
      {
        service_detail: {
          service_name: "",
        },
      },
    ],
    applicable_service_counter: {
      duration: {
        value: 0,
        unit: "",
      },
      service_code: "",
      service_balance: 0,
      service_id: "",
      variant: "",
    },
    organization_id: "",
    card_code: "",
    card_name: "",
    card_type: "",
    sale_channel: "",
    standard_price: 0,
    crossed_out_price: 0,
    store_value_amount: 0,
    store_value_remain: 0,
    // promotional_amount: 0,
    validity_type: {
      details: {
        fixed_validity_period: {
          valid_date_to: "",
        },
      },
    },
    applicable_package: {
      service_list: [
        {
          service_id: "",
          service_code: "",
        },
      ],
    },
    store_used: [
      {
        store_id: "",
        store_code: "",
        address: "",
        store_name: "",
      },
    ],
  });
  let cardUseCode = "";

  const eachCardUseFunc = () => {
    axios
      .get(
        `https://api-store.rocket-tech.app/1.0.0/cardInside?organization_code=T94&card_code=${cardUseCode}`
      )
      .then((data) => {
        setbeforeCardUse({
          ...data.data.result,
          ...(data?.data?.result?.applicable_services_store_value?.length === 0 && {
            applicable_services_store_value: allService,
          }),
          ...(data?.data?.result?.is_all_store_used && {
            store_used: allStore,
          }),
          // applicable_services_store_value: allService,
        });
      })
      .catch((err) => console.log(err, "\nSomething wrong on getting all cards, try again!"));
  };

  // Show Walkin Page
  let [HidetoShowWalkinPage, setHidetoShowWalkinPage] = useState(true);
  let [WalkInPage, setWalkInPage] = useState(false);
  const [onlineBooking, setOnlineBooking] = useState(false);
  let [ShopSelectProcess, setShopSelectProcess] = useState(false);

  let [storeDetail, setStoreDetail] = useState({ address: "" });

  let getStoreDetail = () => {
    axios
      .get(
        `https://api-store.rocket-tech.app/1.0.0/chooseStoreFromList/?organization_code=T94&store_code=${beforeCardUse.store_used[0].store_code}`
      )
      .then((data) => {
        setStoreDetail(data.data.result);
      })
      .catch((err) => console.log(err, "\nSomething wrong on get Store Detail, try again!"));
  };

  // set service code for use
  let [serviceCode, setServiceCode] = useState("");

  // get card uses code
  let [getCardCode, setGetCardCode] = useState("");
  const getCardUsesCode = () => {
    axios
      .get(`https://api-store.rocket-tech.app/1.0.0/getCardUsesCode?card_code=${getCardCode}`)
      .then((data) => {
        localStorage.setItem("cardUsesCode", data?.data?.result[0]?.card_use_code);
        let payload: ISetCardDetail = {
          card_name: data?.data?.result[0].card_name,
          card_type: data?.data?.result[0].card_type,
        };
        dispatch(setCardDetail(payload));
        if (payload.card_type === "counter") {
          dispatch(setCurrentPath("/cards"));
        } else if (payload.card_type === "store value") {
          dispatch(setCurrentPath("/cardsSV"));
        }
      })
      .catch((err) => console.log(err, "\nSomething wrong on get Store Detail, try again!"));
  };

  // get Applicate service remain
  let [applicateServiceRemain, setapplicateServiceRemain] = useState({
    applicable_services_remain: 0,
    store_value_remain: 0,
  });
  // let applicateServiceRemain: any[] = [];
  let lastURL = window.location.href.split("#").pop();

  const getApplicateRemain = () => {
    axios
      .get(`https://api-store.rocket-tech.app/1.0.0/getCardUsesCode?card_code=${cardUseCode}`)
      .then((data) => {
        setapplicateServiceRemain(data.data.result[0]);
      })
      .catch((err) => console.log(err, "\nSomething wrong on get Store Detail, try again!"));
  };

  const clickUseCard = () => {
    // dispatch(setCurrentPath(location.pathname));
  };

  return (
    <>
      {!showCardsBasket ? (
        <>
          <div className="wholePage">
            <div className="topCardTabs">
              <ul className="nav nav-tabs nav-bar topNav" id="myTab" role="tablist">
                <li className="nav-item item">
                  <a
                    className="nav-link active"
                    id="butCards-tab"
                    data-toggle="tab"
                    href="#butCards"
                    role="tab"
                    aria-controls="butCards"
                    aria-selected="true"
                    onClick={() => {
                      setShowMyCards(false);
                    }}
                  >
                    Buy Cards
                  </a>
                </li>
                <li className="nav-item item">
                  <a
                    className="nav-link"
                    id="myCards-tab"
                    data-toggle="tab"
                    href="#myCards"
                    role="tab"
                    aria-controls="myCards"
                    aria-selected="false"
                    onClick={() => {
                      setShowMyCards(true);
                      setMyAll(true);
                    }}
                  >
                    My Cards
                  </a>
                </li>
              </ul>
            </div>
            <div className="topTabs">
              {showMyCards ? (
                <ul className="nav nav-tabs nav-bar topNav" id="myTab" role="tablist">
                  <li className="nav-item item">
                    <a
                      className="nav-link all active"
                      id="all-tab"
                      data-toggle="tab"
                      href="#all"
                      role="tab"
                      aria-controls="all"
                      aria-selected="true"
                      onClick={() => {
                        setMyAll(true);
                        setMyStoredValueTab(false);
                        setMyCounterTab(false);
                        setMyPackageTab(false);
                      }}
                    >
                      All
                    </a>
                  </li>
                  <li className="nav-item item">
                    <a
                      className="nav-link"
                      id="storedValue-tab"
                      data-toggle="tab"
                      href="#storedValue"
                      role="tab"
                      aria-controls="storedValue"
                      aria-selected="false"
                      onClick={() => {
                        setMyAll(false);
                        setMyCounterTab(false);
                        setMyStoredValueTab(true);
                        setMyPackageTab(false);
                      }}
                    >
                      Stored value
                    </a>
                  </li>
                  <li className="nav-item item">
                    <a
                      className="nav-link"
                      id="counter-tab"
                      data-toggle="tab"
                      href="#counter"
                      role="tab"
                      aria-controls="counter"
                      aria-selected="false"
                      onClick={() => {
                        setMyAll(false);
                        setMyStoredValueTab(false);
                        setMyPackageTab(false);
                        setMyCounterTab(true);
                      }}
                    >
                      Counter
                    </a>
                  </li>
                  <li className="nav-item item">
                    <a
                      className="nav-link"
                      id="package-tab"
                      data-toggle="tab"
                      href="#package"
                      role="tab"
                      aria-controls="package"
                      aria-selected="false"
                      onClick={() => {
                        setMyAll(false);
                        setMyStoredValueTab(false);
                        setMyPackageTab(true);
                        setMyCounterTab(false);
                      }}
                    >
                      Package
                    </a>
                  </li>
                </ul>
              ) : (
                <ul className="nav nav-tabs nav-bar topNav" id="myTab" role="tablist">
                  <li className="nav-item item">
                    <a
                      className="nav-link all active"
                      id="all-tab"
                      data-toggle="tab"
                      href="#all"
                      role="tab"
                      aria-controls="all"
                      aria-selected="true"
                      onClick={() => {
                        setAll(true);
                        setStoredValueTab(false);
                        setCounterTab(false);
                        setPackageTab(false);
                      }}
                    >
                      All
                    </a>
                  </li>
                  <li className="nav-item item">
                    <a
                      className="nav-link"
                      id="storedValue-tab"
                      data-toggle="tab"
                      href="#storedValue"
                      role="tab"
                      aria-controls="storedValue"
                      aria-selected="false"
                      onClick={() => {
                        setStoredValueTab(true);
                        setAll(false);
                        setCounterTab(false);
                        setPackageTab(false);
                        storedValueFunc();
                      }}
                    >
                      Stored value
                    </a>
                  </li>
                  <li className="nav-item item">
                    <a
                      className="nav-link"
                      id="counter-tab"
                      data-toggle="tab"
                      href="#counter"
                      role="tab"
                      aria-controls="counter"
                      aria-selected="false"
                      onClick={() => {
                        setCounterTab(true);
                        setAll(false);
                        setStoredValueTab(false);
                        setPackageTab(false);
                        counterTypeFunc();
                      }}
                    >
                      Counter
                    </a>
                  </li>
                  <li className="nav-item item">
                    <a
                      className="nav-link"
                      id="package-tab"
                      data-toggle="tab"
                      href="#package"
                      role="tab"
                      aria-controls="package"
                      aria-selected="false"
                      onClick={() => {
                        setPackageTab(true);
                        setCounterTab(false);
                        setAll(false);
                        setStoredValueTab(false);
                        packageTypeFunc();
                      }}
                    >
                      Package
                    </a>
                  </li>
                </ul>
              )}
            </div>
          </div>

          {showMyCards ? (
            <div className="wholeGray">
              {myAll && (
                <>
                  {myAllCards ? (
                    myAllCards.map((item, i) => (
                      //<Link to={`#${item["card_code"]}`} key={i}>
                      <div
                        className={"singleCard"}
                        key={i}
                        onClick={() => {
                          setShowCardsBasket(true);
                          cardUseCode = item["card_code"];
                          let payload: ISetCardRedux = {
                            card_code: item["card_code"] || "",
                            card_use_code: item["card_use_code" || ""],
                          };
                          dispatch(setCard(payload));
                          setGetCardCode(item["card_code"]);
                          eachCardUseFunc();
                          getApplicateRemain();
                        }}
                      >
                        <div className="cardInside">
                          <div className="leftSide">
                            <h1>{item["card_name"]}</h1>
                            {item["card_type"] === "counter" && (
                              <h2>
                                Quantity {item["applicable_services_remain"]}
                                {/* {item["crossed_out_price"]} */}
                              </h2>
                            )}
                            {item["card_type"] === "store value" && (
                              <h2>
                                ฿ {item["store_value_remain"]}
                                {/* {item["store_value_amount"]} */}
                              </h2>
                            )}

                            <hr />
                            <p>{item["description"]}</p>
                            <div className="validityStick">
                              <p>Validity : 6 months</p>
                            </div>
                          </div>
                          <div className="rightSide">
                            <img src={item["card_cover_image_url"]} />
                          </div>
                        </div>
                      </div>
                      //</Link>
                    ))
                  ) : (
                    <p>Nothing to show!</p>
                  )}
                </>
              )}
              {myStoredValueTab && (
                <>
                  {myStoreValue ? (
                    myStoreValue.map((item, i) => (
                      //<Link to={`#${item["card_code"]}`} key={i}>
                      <div
                        className={"singleCard"}
                        key={i}
                        onClick={() => {
                          setShowCardsBasket(true);
                          cardUseCode = item["card_code"];
                          let payload: ISetCardRedux = {
                            card_code: item["card_code"] || "",
                            card_use_code: item["card_use_code" || ""],
                          };
                          dispatch(setCard(payload));
                          setGetCardCode(item["card_code"]);
                          eachCardUseFunc();
                          getApplicateRemain();
                        }}
                      >
                        <div className="cardInside">
                          <div className="leftSide">
                            <h1>{item["card_name"]}</h1>
                            <h2>
                              ฿ {item["store_value_remain"]}
                              {/* {item["store_value_amount"]} */}
                            </h2>
                            <hr />
                            <p>{item["description"]}</p>
                            <div className="validityStick">
                              <p>Validity : 6 months</p>
                            </div>
                          </div>
                          <div className="rightSide">
                            <img src={item["card_cover_image_url"]} />
                          </div>
                        </div>
                      </div>
                      //</Link>
                    ))
                  ) : (
                    <p>Nothing to show!</p>
                  )}
                </>
              )}
              {myCounterTab && (
                <>
                  {MyCounterType ? (
                    MyCounterType.map((item, i) => (
                      //<Link to={`#${item["card_code"]}`} key={i}>
                      <div
                        className={"singleCard"}
                        key={i}
                        onClick={() => {
                          setShowCardsBasket(true);
                          cardUseCode = item["card_code"];
                          let payload: ISetCardRedux = {
                            card_code: item["card_code"] || "",
                            card_use_code: item["card_use_code" || ""],
                          };
                          dispatch(setCard(payload));
                          setGetCardCode(item["card_code"]);
                          eachCardUseFunc();
                          getApplicateRemain();
                        }}
                      >
                        <div className="cardInside">
                          <div className="leftSide">
                            <h1>{item["card_name"]}</h1>
                            <h2>Quantity {item["applicable_services_remain"]}</h2>
                            <hr />
                            <p>{item["description"]}</p>
                            <div className="validityStick">
                              <p>Validity : 6 months</p>
                            </div>
                          </div>
                          <div className="rightSide">
                            <img src={item["card_cover_image_url"]} />
                          </div>
                        </div>
                      </div>
                      //</Link>
                    ))
                  ) : (
                    <p>Nothing to show!</p>
                  )}
                </>
              )}
              {myPackageTab && (
                <>
                  {MyPackage ? (
                    MyPackage.map((item, i) => (
                      //<Link to={`#${item["card_code"]}`} key={i}>
                      <div
                        className={"singleCard"}
                        key={i}
                        onClick={() => {
                          setShowCardsBasket(true);
                          cardUseCode = item["card_code"];
                          let payload: ISetCardRedux = {
                            card_code: item["card_code"] || "",
                            card_use_code: item["card_use_code" || ""],
                          };
                          dispatch(setCard(payload));
                          eachCardUseFunc();
                        }}
                      >
                        <div className="cardInside">
                          <div className="leftSide">
                            <h1>{item["card_name"]}</h1>
                            <h2>฿ {item["standard_price"]}</h2>
                            <hr />
                            <p>{item["description"]}</p>
                            <div className="validityStick">
                              <p>Validity : 6 months</p>
                            </div>
                          </div>
                          <div className="rightSide">
                            <img src={item["card_cover_image_url"]} />
                          </div>
                        </div>
                      </div>
                      //</Link>
                    ))
                  ) : (
                    <p>Nothing to show!</p>
                  )}
                </>
              )}
            </div>
          ) : (
            <div className="wholeGray">
              {all && (
                <>
                  {allCards ? (
                    allCards
                      .slice(0)
                      .reverse()
                      .map((item, i) => (
                        <Link to={`/cards/${item["card_code"]}`} key={i}>
                          <div className={"singleCard"} key={i}>
                            <div className="cardInside">
                              <div className="leftSide">
                                <h1>{item["card_name"]}</h1>
                                {/* {item["card_type"] === "store value" && (
                                  <h2>฿ {item["standard_price"]}</h2>
                                )}
                                {item["card_type"] === "counter" && (
                                  <h2>
                                    Quantity {item["applicable_service_counter"]["service_balance"]}
                                  </h2>
                                )} */}
                                <h2>฿ {item["standard_price"]}</h2>

                                <hr />
                                <p>{item["description"]}</p>
                                <div className="validityStick">
                                  <p>Validity : 6 months</p>
                                </div>
                              </div>
                              <div className="rightSide">
                                <img src={item["card_cover_image_url"]} />
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))
                  ) : (
                    <p>Nothing to show!</p>
                  )}
                </>
              )}
              {storedValueTab && (
                <>
                  {storedValue ? (
                    storedValue.map((item, i) => (
                      <Link to={`/cards/${item["card_code"]}`} key={i}>
                        <div className={"singleCard"} key={i}>
                          <div className="cardInside">
                            <div className="leftSide">
                              <h1>{item["card_name"]}</h1>
                              <h2>฿ {item["standard_price"]}</h2>
                              <hr />
                              <p>{item["description"]}</p>
                              <div className="validityStick">
                                <p>Validity : 6 months</p>
                              </div>
                            </div>
                            <div className="rightSide">
                              <img src={item["card_cover_image_url"]} />
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))
                  ) : (
                    <p>Nothing to show!</p>
                  )}
                </>
              )}
              {counterTab && (
                <>
                  {counterType ? (
                    counterType.map((item, i) => (
                      <Link to={`/cards/${item["card_code"]}`} key={i}>
                        <div className={"singleCard"} key={i}>
                          <div className="cardInside">
                            <div className="leftSide">
                              <h1>{item["card_name"]}</h1>
                              <h2>
                                ฿ {item["standard_price"]}
                                {/* Quantity {item["applicable_service_counter"]["service_balance"]} */}
                              </h2>
                              <hr />
                              <p>{item["description"]}</p>
                              <div className="validityStick">
                                <p>Validity : 6 months</p>
                              </div>
                            </div>
                            <div className="rightSide">
                              <img src={item["card_cover_image_url"]} />
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))
                  ) : (
                    <p>Nothing to show!</p>
                  )}
                </>
              )}
              {packageTab && (
                <>
                  {packageType ? (
                    packageType.map((item, i) => (
                      <Link to={`/cards/${item["card_code"]}`} key={i}>
                        <div className={"singleCard"} key={i}>
                          <div className="cardInside">
                            <div className="leftSide">
                              <h1>{item["card_name"]}</h1>
                              <h2>฿ {item["standard_price"]}</h2>
                              <hr />
                              <p>{item["description"]}</p>
                              <div className="validityStick">
                                <p>Validity : 6 months</p>
                              </div>
                            </div>
                            <div className="rightSide">
                              <img src={item["card_cover_image_url"]} />
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))
                  ) : (
                    <p>Nothing to show!</p>
                  )}
                </>
              )}
            </div>
          )}
        </>
      ) : (
        <div className="wholeGrayPage">
          {HidetoShowWalkinPage && (
            <div className="bookingCard">
              <div className="topSection">
                <div className="topLeft">
                  <h1>{beforeCardUse.card_name}</h1>
                  <h2>{beforeCardUse.card_type} card</h2>
                </div>
                <div className="topRightValidity">
                  <p>
                    Validity :{" "}
                    {
                      beforeCardUse.validity_type.details.fixed_validity_period.valid_date_to.split(
                        "T"
                      )[0]
                    }
                  </p>
                </div>
              </div>
              <hr />
              <div className="tableDetail">
                <Row>
                  <Col span={8}>
                    <h3>Name :</h3>
                  </Col>
                  <Col span={16}>
                    <p>{userInfo?.fullName}</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    {beforeCardUse.applicable_service_counter.service_balance ? (
                      <h3>Quantity :</h3>
                    ) : (
                      <h3>Store value :</h3>
                    )}
                  </Col>
                  <Col span={16}>
                    <p>
                      {beforeCardUse.applicable_service_counter.service_balance
                        ? applicateServiceRemain.applicable_services_remain
                        : beforeCardUse.store_value_amount}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <h3>Service :</h3>
                  </Col>
                  <Col span={16}>
                    <p>
                      {beforeCardUse?.card_type === "store value" &&
                        beforeCardUse?.applicable_services_store_value.length > 0
                        ? beforeCardUse?.applicable_services_store_value.map((item, index) => {
                          return <p key={index}>{item?.service_detail?.service_name}</p>;
                        })
                        : allService?.map((item, index) => {
                          return <p key={index}>{item?.service_detail?.service_name}</p>;
                        })}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <h3>Store :</h3>
                  </Col>
                  <Col span={16}>
                    <p>
                      {beforeCardUse?.store_used?.length > 0
                        ? beforeCardUse?.store_used?.map((item, index) => {
                          return <p key={index}>{item.store_name}</p>;
                        })
                        : allStore?.map((item, index) => {
                          return <p key={index}>{item?.store_name}</p>;
                        })}
                    </p>
                  </Col>
                </Row>
              </div>
              <div className="myCardDetails">
                <div className="rightDetails"></div>
              </div>
              <div className="botCardDetails">
                <div className="totalPrice">
                  {beforeCardUse.applicable_service_counter.service_balance ? (
                    <p>
                      Quantity : <span>{applicateServiceRemain.applicable_services_remain}</span>
                    </p>
                  ) : (
                    <p>
                      Value left : <span>฿{applicateServiceRemain.store_value_remain}</span>
                      {/* Value left : <span>฿ {beforeCardUse.store_value_amount}</span> */}
                    </p>
                  )}
                </div>
                <div className="rightTotalPrice">
                  <div className="noteButton">
                    <button
                      onClick={() => {
                        setHidetoShowWalkinPage(false);
                        setWalkInPage(true);
                        getStoreDetail();
                        if (beforeCardUse.applicable_service_counter.service_balance) {
                          setServiceCode(beforeCardUse.applicable_service_counter.service_code);
                          dispatch(setCouterService(beforeCardUse.applicable_service_counter));
                        }

                        // getCardUsesCode();
                        clickUseCard();
                      }}
                    >
                      Use
                    </button>
                  </div>
                </div>
              </div>
              <button
                className="btn btn-block mt-5"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowCardsBasket(false);
                  // window.location.href = "/cards";
                }}
              >
                Click to back
              </button>
            </div>
          )}
          {WalkInPage && (
            <div className="threeTab">
              <div
                className="tab"
                onClick={() => {
                  setWalkInPage(false);
                  setShopSelectProcess(true);
                  dispatch(setChannel("walk-in"));
                }}
              >
                <h2>Store walk-in</h2>
                <div className="arrowIcon">&gt;</div>
              </div>
              {beforeCardUse?.sale_channel === "Online and offline" && (
                <div
                  className="tab"
                  onClick={() => {
                    setWalkInPage(false);
                    setOnlineBooking(true);
                    setShopSelectProcess(true);
                    dispatch(setChannel("online"));
                  }}
                >
                  <h2>Online booking</h2>
                  <div className="arrowIcon">&gt;</div>
                </div>
              )}

              <button
                className="btn btn-block mt-5"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setWalkInPage(false);
                  setHidetoShowWalkinPage(true);
                }}
              >
                Click to back
              </button>
            </div>
          )}
          {ShopSelectProcess && (
            <div className="threeTab">
              {beforeCardUse.store_used.map((item, i) => (
                <div
                  className="tab shopDiv"
                  key={i}
                  onClick={() => {
                    if (beforeCardUse?.card_type === "counter") {
                      localStorage.setItem("bookByServiceCodeCounter", serviceCode);
                      localStorage.setItem("store_code", item.store_code);
                      localStorage.removeItem("preBookingBody");
                      getCardUsesCode();
                      localStorage.setItem("path", "/cards");
                      history.push(`/S-Home/services/${serviceCode}`);
                    } else {
                      localStorage.setItem("bookByCard", JSON.stringify(beforeCardUse));
                      localStorage.setItem("store_code", item.store_code);
                      localStorage.removeItem("preBookingBody");
                      getCardUsesCode();
                      localStorage.setItem("path", "/cardsSV");
                      history.push("/S-Home");
                    }
                    // if (serviceCode !== "") {
                    //   localStorage.setItem("bookByServiceCodeCounter", serviceCode);
                    //   localStorage.setItem("store_code", item.store_code);
                    //   localStorage.removeItem("preBookingBody");
                    //   getCardUsesCode();
                    //   history.push(`/S-Home/services/${serviceCode}`);
                    // } else {
                    //   localStorage.setItem("bookByCard", JSON.stringify(beforeCardUse));
                    //   localStorage.setItem("store_code", item.store_code);
                    //   localStorage.removeItem("preBookingBody");
                    //   getCardUsesCode();
                    //   history.push("/S-Home");
                    // }
                    // location.reload();
                  }}
                >
                  <div className="leftShopDetail">
                    <h3>{item.store_name}</h3>
                    <p>{item.address}</p>
                    <p className="small">09.00 - 21.00 (จันทร์ - อาทิตย์)</p>
                  </div>
                  <div className="rightShopDetail">
                    <p>
                      Distance <br />
                      <StyledTurnE /> 2.5km
                    </p>
                  </div>
                </div>
              ))}
              <button
                className="btn btn-block mt-5"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setWalkInPage(false);
                  setShopSelectProcess(false);
                  setHidetoShowWalkinPage(true);
                }}
              >
                Click to back
              </button>
            </div>
          )}
        </div>
      )}
      <EmptyFooterSpace />
    </>
  );
};
const EmptyFooterSpace = styled.div`
  height: ${(props) => `${props.theme.footer.height}`};
`;

const StyledTurnE = styled(WalkinTurnE)`
  cursor: pointer;
`;
const StyledNote = styled(basketDetailsNote)`
  cursor: pointer;
`;
