import { IGetServiceList, IGetStoresOnService } from "@interfaces";
import storefrontAxiosClient from "./storeFrontAxiosClient";


export const serviceApi = {
  getServiceList: (params: IGetServiceList) => {
    const url = "service/getServices";
    return storefrontAxiosClient.get(url, { params });
  },
  getStoreonService: (params: IGetStoresOnService) => {
    const url = "clientSite/getStoresOnService";
    return storefrontAxiosClient.get(url, { params });
  },
};