import { Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ComponentAppRoute, ComponentToast } from "@components";

import { IMerchant, IRoute } from "@interfaces";
import { routes } from "@configs";
import {
  getInfo,
  logout,
  removeConfigClient,
  resetCardUse,
  resetMerchant,
  selectAuth,
  selectConfigClient,
  selectMerchant,
  selectPage,
  setMainColor,
  setMerchant,
} from "@redux";
import { PageHistoryPublic } from "@pages";
import { merchantApi } from "@api";

export const ModuleMain = () => {
  //page hooks
  const dispatch = useDispatch();

  //redux state
  const { auth } = useSelector(selectAuth);
  const { showPopupHistory } = useSelector(selectPage);
  const { _id, store_id } = useSelector(selectMerchant);
  const configClient = useSelector(selectConfigClient);
  //page variable
  const token = auth?.accessToken;
  const link = new URL(location.href);
  const merchantID = link.searchParams.get("merchant_id");
  const historyId = link.searchParams.get("history_id");

  useEffect(() => {
    getUserInfo();
  }, [token]);
  useEffect(() => {
    getMerchant();
  }, []);
  const getUserInfo = () => {
    if (token) {
      dispatch(getInfo());
    }
  };
  useEffect(() => {
    dispatch(resetCardUse());
  }, []);

  const getMerchant = async () => {
    if (_id && !link.pathname.includes("auth")) {
      try {
        const merchantConfig = await merchantApi.getMerchantConfig();
        const merchant = merchantConfig.data.merchant as IMerchant;
        if (merchant) {
          if (store_id === "") {
            dispatch(setMerchant(merchant));
          }
          dispatch(setMainColor(merchant.themeColor));
          if (!merchant.enableAccess) {
            const index = configClient.findIndex((item) => item.merchantId === merchant._id);
            if (index >= 0) {
              dispatch(removeConfigClient(index));
            }
            dispatch(resetMerchant());
            dispatch(logout());
          }
        }
      } catch {
        dispatch(resetMerchant());
      }
    }
  };

  return (
    <BrowserRouter>
      <Switch>
        {routes.map((e: IRoute, key) => (
          <ComponentAppRoute key={key} {...e} />
        ))}
      </Switch>
      <ComponentToast />
      {showPopupHistory && <PageHistoryPublic merchantId={merchantID} historyId={historyId} />}
    </BrowserRouter>
  );
};
